const importScript = (function (oHead) {
  function loadError(oError) {
    throw new URIError(`The script ${oError.target.src} is not accessible.`);
  }
  return function (sSrc, id, fOnload) {
    const oScript = document.createElement("script");
    oScript.type = "text/javascript";
    oScript.onerror = loadError;
    if (id) {
      oScript.id = id;
    }
    if (fOnload) {
      oScript.onload = fOnload;
    }
    oHead.appendChild(oScript);
    oScript.src = sSrc;
  };
})(document.head || document.getElementsByTagName("head")[0]);

export { importScript };
