<template>
  <div class="desktop">
    <DestyHeader
      :class="[
        'desty-header-store',
        { 'desty-header-store-templates': $route.path === '/templates' },
      ]"
      :lang="language"
      subLogo="https://static.desty.app/desty-store-homepage/home/storelogo.svg"
      navKey="products"
      :navs="navList"
      :isLogin="isLogin"
      :extraLinks="extraLinks"
      @handleLang="changeLanguage"
      @handleNav="handleNavAction"
      @handleAction="handleAction"
    >
      <template v-slot:sub-menu-products-content>
        <!-- <div class="content-box">
          <div class="menu-title">{{ $t("headerv2.title") }}</div>
          <div class="sub-menu-box">
            <div
              class="sub-menu-box-item"
              v-for="item in SUB_MENU_LIST"
              :key="item.key"
              @click="handleNavAction(item.key)"
            >
              <img
                class="icon-img-logo"
                :src="`https://static.desty.app/desty-homepage/v2/${item.icon}.svg`"
                alt="Desty App, Desty Omni, Desty Page, Desty Store, Desty Menu"
              />
              {{ item.text }}
            </div>
          </div>
        </div> -->
        <div class="sub-menu-box">
          <div class="omni-box" @click="handleNavAction(omni.key)">
            <img
              class="icon-img-logo"
              :src="`https://static.desty.app/desty-homepage/v2/${omni.icon}.svg`"
              alt="Desty App, Desty Omni, Desty Page, Desty Store, Desty Menu"
            />
            {{ omni.text }}
          </div>
          <div class="divide"></div>
          <div class="sub-title">{{ $t("headerv2.title") }}</div>
          <div class="sub-menu-box-outer">
            <div
              class="sub-menu-box-item"
              v-for="item in subList"
              :key="item.key"
              @click="handleNavAction(item.key)"
            >
              <img
                class="icon-img-logo"
                :src="`https://static.desty.app/desty-homepage/v2/${item.icon}.svg`"
                alt="Desty App, Desty Omni, Desty Page, Desty Store, Desty Menu"
              />
              {{ item.text }}
            </div>
          </div>
          <div class="divide"></div>
          <div class="sub-title">{{ $t("services.title") }}</div>
          <div class="sub-menu-box-outer">
            <div
              v-for="item in serviceList"
              :key="item.key"
              class="sub-menu-box-item w100"
              @click="handleNavAction(item.key)"
            >
              {{ item.text }}
            </div>
          </div>
        </div>
      </template>
      <template v-slot:mobile-sub-menu-products-content>
        <!-- <div
          class="mobile-sub-menu-box-item"
          v-for="item in SUB_MENU_LIST"
          :key="item.key"
          @click="handleNavAction(item.key)"
        >
          <img
            class="icon-img-logo"
            :src="`https://static.desty.app/desty-homepage/v2/${item.icon}.svg`"
            alt="Desty App, Desty Omni, Desty Page, Desty Store, Desty Menu"
          />
          {{ item.text }}
        </div> -->
        <div class="mobile-sub-menu-box">
          <div class="omni-box" @click="handleNavAction(omni.key)">
            <img
              class="icon-img-logo"
              :src="`https://static.desty.app/desty-homepage/v2/${omni.icon}.svg`"
              alt="Desty App, Desty Omni, Desty Page, Desty Store, Desty Menu"
            />
            {{ omni.text }}
          </div>
          <div class="divide"></div>
          <div class="sub-title">{{ $t("headerv2.title") }}</div>
          <div
            class="mobile-sub-menu-box-item"
            v-for="item in subList"
            :key="item.key"
            @click="handleNavAction(item.key)"
          >
            <img
              class="icon-img-logo"
              :src="`https://static.desty.app/desty-homepage/v2/${item.icon}.svg`"
              alt="Desty App, Desty Omni, Desty Page, Desty Store, Desty Menu"
            />
            {{ item.text }}
          </div>
          <div class="divide"></div>
          <div class="sub-title">{{ $t("services.title") }}</div>
          <div
            v-for="item in serviceList"
            :key="item.key"
            class="mobile-sub-menu-box-item w100"
            @click="handleNavAction(item.key)"
          >
            {{ item.text }}
          </div>
        </div>
      </template>
    </DestyHeader>
    <!-- router-view -->
    <router-view></router-view>
    <!--新人激励任务-->
    <!--    <div class="limited-box" ref="limitedRef">-->
    <!--      <div-->
    <!--        v-if="showLimited"-->
    <!--        class="limited-img"-->
    <!--        :class="[{ 'close-img': showLimited === false }]"-->
    <!--      >-->
    <!--        <div @click="showLimited = false" class="close-icon">-->
    <!--          <el-icon class="el-icon-close"></el-icon>-->
    <!--        </div>-->
    <!--        <img :src="limitedImg" alt="" @click="goJoinNow" class="img" />-->
    <!--      </div>-->
    <!--      <div class="float-limited" v-if="!showLimited">-->
    <!--        <el-icon class="el-icon-arrow-down limit-icon"></el-icon>-->
    <!--        <div class="limited-text">{{ $t("home.limited") }}</div>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div class="mobile-limited-box" v-if="isMobile">-->
    <!--      <img :src="moFloat" alt="" @click="openDrawer" class="limited-img" />-->
    <!--      <el-drawer-->
    <!--        :modal-append-to-body="false"-->
    <!--        class="limited-drawer"-->
    <!--        :visible.sync="drawer"-->
    <!--        direction="btt"-->
    <!--        :before-close="CloseDrawer"-->
    <!--      >-->
    <!--        <div @click="CloseDrawer" class="close-icon">-->
    <!--          <el-icon class="el-icon-close"></el-icon>-->
    <!--        </div>-->
    <!--        <img :src="moLimitedimg" alt="" class="img" @click="goJoinNow" />-->
    <!--      </el-drawer>-->
    <!--    </div>-->

    <div
      :class="['float-icon zendesk-box', { hidefloat: zenShow }]"
      @click="openWidget()"
      v-loading="loadingZen"
    >
      <div class="zendesk">
        <div :class="['gHHEeh', { hideHHEeh: zenShow }]">
          <svg
            position="right"
            class="sc-1k07fow-1 cbnSms"
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <path
              d="M10,18 L6,22 L6,18 L10,18 Z M17,6 C19.7614237,6 22,8.23857625 22,11 C22,13.7614237 19.7614237,16 17,16 L17,16 L7,16 C4.23857625,16 2,13.7614237 2,11 C2,8.23857625 4.23857625,6 7,6 L7,6 Z"
              id="🎨icon-fill"
              transform="translate(12.000000, 14.000000) scale(-1, 1) translate(-12.000000, -14.000000) "
            ></path>
          </svg>
        </div>
        <div
          :class="['fxifZC', { hideFxifZC: !zenShow }]"
          @click.stop="closeWidget"
        >
          <svg
            class="sc-1k07fow-0 iHCBIr"
            width="88px"
            height="88px"
            viewBox="0 0 88 88"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <defs>
              <filter
                x="-47.9%"
                y="-47.9%"
                width="195.8%"
                height="195.8%"
                filterUnits="objectBoundingBox"
                id="filter-1"
              >
                <feOffset
                  dx="-4"
                  dy="0"
                  in="SourceAlpha"
                  result="shadowOffsetOuter1"
                ></feOffset>
                <feGaussianBlur
                  stdDeviation="10"
                  in="shadowOffsetOuter1"
                  result="shadowBlurOuter1"
                ></feGaussianBlur>
                <feColorMatrix
                  values="0 0 0 0 0.141176471   0 0 0 0 0.141176471   0 0 0 0 0.141176471  0 0 0 0.2 0"
                  type="matrix"
                  in="shadowBlurOuter1"
                  result="shadowMatrixOuter1"
                ></feColorMatrix>
                <feMerge>
                  <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
                  <feMergeNode in="SourceGraphic"></feMergeNode>
                </feMerge>
              </filter>
            </defs>
            <g
              id="🦴Anatomy"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g
                id="btn/chat-button"
                filter="url(#filter-1)"
                transform="translate(-4.000000, -4.000000)"
                fill="#FFFFFF"
              >
                <g id="⚡️icon" transform="translate(38.000000, 38.000000)">
                  <polygon
                    id="Shape"
                    points="17.2338475 0.833333333 19.1666667 2.76615247 11.9325273 10 19.1666667 17.2338475 17.2338475 19.1666667 10 11.9325273 2.76615247 19.1666667 0.833333333 17.2338475 8.06610603 10 0.833333333 2.76615247 2.76615247 0.833333333 10 8.06610603"
                  ></polygon>
                </g>
              </g>
            </g>
          </svg>
        </div>
      </div>
    </div>
    <a
      href="https://wa.me/6281398399184?text=Halo,%20saya%20membutuhkan%20bantuan%20terkait%20Desty%20Store"
      target="_blank"
      class="float-icon"
    >
      <img :src="whatsappIcon" class="whatsappIcon" @click="ClickWhatsapp" />
    </a>
    <!-- <NewFooter /> -->
    <DestyFooter
      class="detsy-footer"
      navKey="destystore"
      :lang="language"
      @handleNav="handleNavAction"
      @handleLang="changeLanguage"
    />
  </div>
</template>

<script>
import { DestyHeader, DestyFooter } from "desty-design";
// import NewFooter from "../components/NewFooter.vue";
import { importScript } from "../utils/createdScript";
import { SUB_MENU_LIST, SERVICE_LIST } from "@/constant";
import limitedENImg from "../assets/img/limited-img-1111.png";
import limitedIDImg from "../assets/img/id-limitedImg-1111.png";
import moFloat from "../assets/img/mobile-floatIcon-1111.png";
import MolimitedENImg from "../assets/img/mobile-enLimited-1111.png";
import MolimitedIDImg from "../assets/img/mobile-idLimited-1111.png";
import resetUrl from "../utils/resetUrl";
import { TRACKER_LOGIN_QUERY_STR } from "@/constant";
import { handleNav, linkURLAddQuery } from "../utils/util";
import service from "../http/request";

const [omni, ...subList] = SUB_MENU_LIST;

export default {
  components: {
    DestyHeader,
    DestyFooter,
    // NewFooter,
  },
  inject: ["destyTranslateRef"],
  data() {
    return {
      showDialog: 0,
      bottontext1: "start creating button",
      bottontext2: "try without registering button",
      whatsappIcon: `${process.env.VUE_APP_STORE_OSS_URL}/home/whatsappIcon.svg`,
      zenDeskIcon: `${process.env.VUE_APP_STORE_OSS_URL}/home/storeIcon.svg`,
      zenShow: false, // zen弹框是否是打开状态
      loadingZen: false, //  加载zendeskapi
      limitedENImg,
      limitedIDImg,
      showLimited: false,
      userMsg: null,
      MolimitedENImg,
      MolimitedIDImg,
      moFloat,
      drawer: false,
      extraLinks: [
        {
          key: "gallery",
          label: "Gallery",
        },
        {
          key: "templates",
          label: "Templates",
        },
      ],
      omni,
      subList,
      serviceList: SERVICE_LIST,
    };
  },
  computed: {
    isLogin() {
      return this.$store.state.isLogin;
    },
    language() {
      return this.$store.state.language;
    },
    userInfo() {
      return this.$store.state.userInfo;
    },
    isMobile() {
      return this.$store.state.isMobile;
    },
    limitedImg() {
      return this.$store.state.language === "id"
        ? this.limitedIDImg
        : this.limitedENImg;
    },
    moLimitedimg() {
      return this.$store.state.language === "id"
        ? this.MolimitedIDImg
        : this.MolimitedENImg;
    },
    trackParams() {
      return this.$store.state.trackParams;
    },
    navList() {
      return [
        {
          label: this.$t("header.home"),
          key: "home",
        },
        {
          label: this.$t("header.products"),
          key: "products",
          hasSubMenu: true,
        },
        {
          label: this.$t("header.market"),
          key: "market",
        },
        {
          label: this.$t("header.blog"),
          key: "blog",
        },
        {
          label: this.$t("header.helpcenter"),
          key: "helpcenter",
        },
      ];
    },
  },

  methods: {
    handleAction(key) {
      handleNav(key, process.env, this.$route);
    },
    handleNavAction(key) {
      if (key === "destystore" || key === "subLogo") {
        this.$router.push(linkURLAddQuery("/", this.$route));
        return;
      }
      if (key === "gallery" || key === "templates") {
        this.$router.push(linkURLAddQuery(`/${key}`, this.$route));
        return;
      }
      handleNav(key, process.env, this.$route);
    },
    async changeLanguage(locale) {
      this.$i18n.locale = locale;
      this.$store.commit("setLanguage", locale);
      if (this.$store.state.userInfo !== null) {
        const language = locale === "en" ? "English" : "Bahasa Indonesia";
        service.post("/seller/updateStoreDetail", {
          sellerLocale: language,
        });
      }
      try {
        await this.destyTranslateRef()?.setCommonTranslate?.({
          dataKey: "lang",
          dataValue: locale,
        });
      } catch (error) {
        console.log("setCommonTranslate error", error);
      }
    },
    ClickWhatsapp() {
      this.$logEvent("ds-s:click open contact us by wa");
    },
    // 设备id处理
    initDeviceId() {
      const params = this.$route.query;
      // eslint-disable-next-line camelcase
      const {
        utm_campaign,
        utm_content,
        utm_medium,
        utm_source,
        utm_term,
        amp_device_id,
      } = params;

      let content = "",
        source = "",
        medium = "",
        campaign = "",
        term = "",
        deviceId = "";
      if (utm_content) {
        content = `&utm_content=${utm_content}`;
      } else {
        content = "";
      }
      if (utm_source) {
        source = `&utm_source=${utm_source}`;
      } else {
        source = "";
      }
      if (utm_medium) {
        medium = `&utm_medium=${utm_medium}`;
      } else {
        medium = "";
      }
      if (utm_campaign) {
        campaign = `&utm_campaign=${utm_campaign}`;
      } else {
        campaign = "";
      }
      if (utm_term) {
        term = `&utm_term=${utm_term}`;
      } else {
        term = "";
      }
      if (amp_device_id) {
        deviceId = `&amp_device_id=${amp_device_id}`;
      } else {
        deviceId = "";
      }
      const t = `${source}${medium}${campaign}${content}${term}${deviceId}`;
      this.$store.commit("setTrackParams", t.slice(1));
    },
    openWidget() {
      const hasZen = document.querySelector("#ze-snippet");
      // console.log(hasZen, '======222');
      if (hasZen) {
        window.zE("messenger", "open");
        this.zenShow = true;
      } else {
        this.loadingZen = true;
        // https://developer.zendesk.com/documentation/classic-web-widget-sdks/web-widget/quickstart-tutorials/creating-a-launcher-for-the-web-widget/
        importScript(
          "https://static.zdassets.com/ekr/snippet.js?key=2bc9ab27-5f00-43ac-a5ab-355609e8c420",
          "ze-snippet",
          () => {
            // window.zE("webWidget", "hide");
            window.zE("messenger", "open");
            this.zenShow = true;
            setTimeout(() => {
              this.loadingZen = false;
            }, 800);
          }
        );
      }
      this.$logEvent("ds-s:click open live-chat");
    },
    closeWidget() {
      this.zenShow = false;
      console.log("---into closeWidget");
      window.zE("messenger", "close");
    },
    cardBlur() {
      this.documentClickFun = (e) => {
        const cardClick = this.$refs?.limitedRef?.contains(e.target);
        if (!cardClick) {
          this.showLimited = false;
        } else {
          this.showLimited = true;
        }
      };
      document.addEventListener("click", this.documentClickFun);
    },
    onClickLogin() {
      this.$store.commit("setPreRouter", this.$route.path);
      // const entryPage = "home";
      window.open(
        resetUrl(
          `${process.env.VUE_APP_BASE_URL}/common/login?${TRACKER_LOGIN_QUERY_STR}`,
          this.trackParams
        )
        // "_self"
      );

      // if (this.$route.path === "/store") {
      //   entryPage = "store";
      // }
      this.$logEvent("ds-s:click web login button", {
        source: "homepage",
      });
      // this.showLoginBox = true;
    },
    gotoDashboard() {
      window.open(
        resetUrl(
          `${process.env.VUE_APP_BASE_URL}/store/dashboard`,
          this.trackParams
        ),
        "_self"
      );
    },
    goJoinNow() {
      // if (this.userMsg) {
      //   this.gotoDashboard();
      // } else {
      //   this.onClickLogin();
      // }
      this.onClickLogin();
    },
    CloseDrawer() {
      this.drawer = false;
      document.body.classList.remove("modal_open");
      // document.body.classList.remove("el-popup-parent--hidden");
    },
    openDrawer() {
      this.drawer = true;
      document.body.classList.add("modal_open");
    },
  },
  mounted() {
    this.$store.webpSupport = true;
    this.initDeviceId();
    this.$nextTick(() => {
      this.cardBlur();
      if (this.isMobile) {
        this.openDrawer();
      }
    });
    this.showLimited = true;
  },
  beforeDestroy() {
    document.removeEventListener("click", this.documentClickFun);
  },
  watch: {
    userInfo: {
      handler(val) {
        if (val) {
          this.userMsg = val;
        } else {
          this.userMsg = JSON.parse(
            localStorage.getItem("storeHomeVuex")
          ).userInfo;
        }
      },
    },
  },
};
</script>

<style lang="less" scoped>
@-webkit-keyframes launcherOnOpen {
  0% {
    -webkit-transform: translateY(0px) rotate(0deg);
    transform: translateY(0px) rotate(0deg);
  }

  30% {
    -webkit-transform: translateY(-5px) rotate(2deg);
    transform: translateY(-5px) rotate(2deg);
  }

  60% {
    -webkit-transform: translateY(0px) rotate(0deg);
    transform: translateY(0px) rotate(0deg);
  }

  90% {
    -webkit-transform: translateY(-1px) rotate(0deg);
    transform: translateY(-1px) rotate(0deg);
  }

  100% {
    -webkit-transform: translateY(-0px) rotate(0deg);
    transform: translateY(-0px) rotate(0deg);
  }
}
@keyframes launcherOnOpen {
  0% {
    -webkit-transform: translateY(0px) rotate(0deg);
    transform: translateY(0px) rotate(0deg);
  }

  30% {
    -webkit-transform: translateY(-5px) rotate(2deg);
    transform: translateY(-5px) rotate(2deg);
  }

  60% {
    -webkit-transform: translateY(0px) rotate(0deg);
    transform: translateY(0px) rotate(0deg);
  }

  90% {
    -webkit-transform: translateY(-1px) rotate(0deg);
    transform: translateY(-1px) rotate(0deg);
  }

  100% {
    -webkit-transform: translateY(-0px) rotate(0deg);
    transform: translateY(-0px) rotate(0deg);
  }
}

@keyframes webSDKOnLoad {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes webSDKOnLoad {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    transform: translate(-60px);
    opacity: 0;
  }
  100% {
    transform: translate(0);
    opacity: 1;
  }
}

@keyframes fadenum {
  100% {
    transform: translateX(-200px);
    opacity: 0;
  }
}
@filter-bg: rgba(239, 250, 255, 0.98);
@filter-templates-bg: rgba(204, 239, 255, 0.98);

.desktop {
  overflow: hidden;
  .limited-model {
    // position: fixed;
    // top: 0;
    // right: 0;
    // bottom: 0;
    // left: 0;
    // z-index: 1000001;
    // background-color: rgba(0, 0, 0, 0.3);
    // backdrop-filter: blur(10px);
    @media screen and (max-width: 960px) {
      display: none;
    }
  }
  .float-icon {
    position: fixed;
    bottom: 0;
    right: 86px;
    z-index: 9;
    .whatsappIcon {
      cursor: pointer;
      width: 82px;
      height: 82px;
    }
    &.zendesk-box {
      height: 66px;
      width: 66px;
      background: #ffffff;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      bottom: 15px;
      right: 15px;
      border: 0px;
      margin-top: 0px;
      box-shadow: rgb(36 36 36 / 10%) 0px 8px 24px 0px,
        rgb(71 69 123 / 4%) 0px 1px 4px 0px;
      animation: 0.2s ease-in 0s 1 normal none running webSDKOnLoad,
        0s ease 0s 1 normal none running undefined;
      z-index: 1000000;
      border-radius: 50%;

      .zendesk {
        position: relative;
        overflow: hidden;
        border-radius: 50%;
        width: 100%;
        height: 100%;
        background-color: rgb(0, 99, 219);
        .gHHEeh {
          position: absolute;
          left: 0px;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          height: 100%;
          width: 100%;
          transition: top 0.5s cubic-bezier(0.66, 0, 0.12, 1) 0s;
          top: 0px;
          color: #ffffff;
          svg {
            fill: currentColor;
            width: 60% !important;
            height: 60% !important;
          }
        }
        .hideHHEeh {
          top: -100%;
          @media screen and (max-width: 960px) {
            top: 0;
          }
        }
        .fxifZC {
          position: absolute;
          left: 0px;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          height: 100%;
          width: 100%;
          transition: top 0.5s cubic-bezier(0.66, 0, 0.12, 1) 0s;
          top: 0;
          @media screen and (max-width: 960px) {
            display: none;
          }
        }
        .hideFxifZC {
          top: 100%;
        }
      }
    }
    &.hidefloat {
      animation: 0.7s ease-in 0s 1 normal none running fadeInLeft,
        0.7s cubic-bezier(0.66, 0, 0.12, 1) 0s 1 normal none running fadeInRight;
      @media screen and (max-width: 960px) {
        opacity: 0;
      }
    }
  }
  .scroll-top {
    top: 0;
    position: fixed;
    z-index: 12;
    margin-top: 0;
    width: 100%;
    background: rgba(239, 250, 255, 0.9);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    -moz-backdrop-filter: blur(10px);
    -o-backdrop-filter: blur(10px);
  }
  .limited-box {
    position: fixed;
    left: 0px;
    bottom: 53px;
    width: 20px;
    z-index: 1000002;
    .limited-img {
      position: relative;
      // animation: fadeIn 0.2s ease-out 1s backwards;
      animation: fadeIn 1s;
      .close-icon {
        position: absolute;
        left: 480px;
        color: #fff;
        font-size: 24px;
        font-weight: bold;
        top: 18px;
        cursor: pointer;
      }
      img {
        width: 520px;
        cursor: pointer;
      }
    }
    .close-img {
      bottom: -200px;
      animation: fadenum 3s;
    }
    .float-limited {
      cursor: pointer;
      margin-left: -150px;
      margin-bottom: 145px;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: center;
      transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      background: #0022cc;
      width: 340px;
      height: 52px;
      font-family: "Plus Jakarta Sans Bold";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      text-transform: uppercase;
      color: #ffffff;
      .limit-icon {
        font-weight: bold;
      }
      .limited-text {
        margin-right: 10px;
      }
    }
    @media screen and (max-width: 960px) {
      display: none;
    }
  }
  .mobile-limited-box {
    display: none;
    @media screen and (max-width: 960px) {
      display: block;
      position: fixed;
      left: 0px;
      bottom: 0px;
      z-index: 1000002;
      .limited-img {
        width: 80px;
        height: 80px;
      }
      /deep/.limited-drawer {
        .el-drawer {
          background: none !important;
          height: min-content !important;
          .el-drawer__header {
            display: none;
          }
          .el-drawer__body {
            position: relative;
            .close-icon {
              position: absolute;
              right: 14px;
              top: 14px;
              color: #fff;
              font-size: 24px;
              font-weight: bold;
            }
            .img {
              width: 100%;
              // position: fixed;
              // bottom: 0;
              margin-bottom: -3px;
            }
          }
        }
        .v-modal {
          pointer-events: none;
        }
      }
    }
  }
  .desty-header-store {
    background-color: @filter-bg;
    /deep/.sub-header {
      .drawer-box-down {
        background-color: @filter-bg;
      }
    }
    /deep/.sub-header-fixed {
      background-color: @filter-bg;
    }
    // .menu-title {
    //   font-weight: 800;
    //   font-size: 18px;
    //   color: #a6a6a6;
    // }
    // .sub-menu-box {
    //   display: flex;
    //   flex-wrap: wrap;
    //   width: 300px;
    //   .sub-menu-box-item {
    //     display: flex;
    //     align-items: center;
    //     width: 50%;
    //     font-size: 18px;
    //     color: rgba(0, 0, 0, 0.5);
    //     margin-top: 26px;
    //     .icon-img-logo {
    //       margin-right: 13px;
    //       width: 24px;
    //       height: 24px;
    //     }
    //     &:nth-child(2n) {
    //       justify-content: flex-end;
    //     }
    //   }
    // }
    // .mobile-sub-menu-box-item {
    //   display: flex;
    //   align-items: center;
    //   margin-bottom: 16px;
    //   font-size: 18px;
    //   .icon-img-logo {
    //     margin-right: 8px;
    //     width: 20px;
    //     height: 20px;
    //   }
    //   &:last-child {
    //     margin-bottom: 0;
    //   }
    // }
    .icon-img-logo {
      margin-right: 13px;
      width: 24px;
      height: 24px;
    }
    .divide {
      border-top: 1px solid #d9d9d9;
    }
    .sub-title {
      color: rgb(0, 0, 0);
      font-size: 18px;
      font-weight: 600;
      line-height: 18px;
    }
    .omni-box {
      font-size: 18px;
      color: rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
    }
    .sub-menu-box {
      width: 264px;

      .divide {
        margin: 20px 0;
      }
      .sub-menu-box-outer {
        display: flex;
        flex-wrap: wrap;
        .sub-menu-box-item {
          display: flex;
          align-items: center;
          width: 50%;
          font-size: 18px;
          color: rgba(0, 0, 0, 0.5);
          margin-top: 20px;
          &.w100 {
            width: 100%;
          }
        }
      }
    }
    .mobile-sub-menu-box {
      width: 232px;
      .divide {
        margin: 16px 0;
      }

      .mobile-sub-menu-box-item {
        display: flex;
        align-items: center;
        margin-top: 16px;
        font-size: 18px;
        &:first-of-type {
          margin-top: 0;
        }
      }
    }
  }
  .desty-header-store-templates {
    background-color: @filter-templates-bg;
    /deep/.sub-header {
      .drawer-box-down {
        background-color: @filter-templates-bg;
      }
    }
    /deep/.sub-header-fixed {
      background-color: @filter-templates-bg;
    }
  }
  .detsy-footer {
    position: relative;
    margin-top: -55px;
    /deep/.desty-footer-pc,
    /deep/.desty-footer-mobile {
      background-color: #1462e1;
    }
  }

  @media screen and (max-width: 960px) {
    width: 100%;
    .float-icon {
      right: 2px;
      bottom: 76px;
    }
    .scroll-top {
      width: 100%;
    }
  }
}
</style>
<style lang="less">
.chFxkt,
.dPtQUU {
  left: auto;
  right: 20px;
}
</style>
